.footer-section{
    background-color: var(--light-black);
    .container{
        padding: 8.8rem 0;
        .grid-footer__info{
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 2.8rem;
            column-gap: 2.8rem;
            h3{
                color: var(--primary-color);
            }
            p{
                color: var(--white-color);
            }
            .grid-footer__info-section{
                padding: 1.8rem;
                .grid-footer__info-section-sm{
                    display: flex;
                    li{
                        margin-left: 2.8rem;
                        a{
                            text-decoration: none;
                        }
                    }
                }
                ul{
                    list-style: none;
                    line-height: 3.2rem;
                    li{
                        color: var(--white-color);
                        a{
                            color: var(--white-color);
                        }
                    }
                }                
            }        
            .grid-footer__info-section:last-child{
                ul{
                    li{
                        a{
                            text-decoration: none;
                        }
                    }
                }
            }
        }        
    }
    .footer-section__copy{
        text-align: center;
        background-color: var(--black-color);
        padding: 1.8rem 0 0.8rem 0;
        p{
            color: var(--white-color);
        }
    }
}

// Medium devices 
@media (min-width: 768px) {
    .footer-section{
        .container{
            .grid-footer__info{
                grid-template-columns: repeat(3, 1fr);
            }        
        }
    }    
}