.footer-section {
  background-color: var(--light-black);
}
.footer-section .container {
  padding: 8.8rem 0;
}
.footer-section .container .grid-footer__info {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2.8rem;
  column-gap: 2.8rem;
}
.footer-section .container .grid-footer__info h3 {
  color: var(--primary-color);
}
.footer-section .container .grid-footer__info p {
  color: var(--white-color);
}
.footer-section .container .grid-footer__info .grid-footer__info-section {
  padding: 1.8rem;
}
.footer-section .container .grid-footer__info .grid-footer__info-section .grid-footer__info-section-sm {
  display: flex;
}
.footer-section .container .grid-footer__info .grid-footer__info-section .grid-footer__info-section-sm li {
  margin-left: 2.8rem;
}
.footer-section .container .grid-footer__info .grid-footer__info-section .grid-footer__info-section-sm li a {
  text-decoration: none;
}
.footer-section .container .grid-footer__info .grid-footer__info-section ul {
  list-style: none;
  line-height: 3.2rem;
}
.footer-section .container .grid-footer__info .grid-footer__info-section ul li {
  color: var(--white-color);
}
.footer-section .container .grid-footer__info .grid-footer__info-section ul li a {
  color: var(--white-color);
}
.footer-section .container .grid-footer__info .grid-footer__info-section:last-child ul li a {
  text-decoration: none;
}
.footer-section .footer-section__copy {
  text-align: center;
  background-color: var(--black-color);
  padding: 1.8rem 0 0.8rem 0;
}
.footer-section .footer-section__copy p {
  color: var(--white-color);
}

@media (min-width: 768px) {
  .footer-section .container .grid-footer__info {
    grid-template-columns: repeat(3, 1fr);
  }
}